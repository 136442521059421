import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { styled } from '../../../stitches.config';

import { Icon } from '../icon';

export type Props = {
  onClose: () => void;
  children: React.ReactElement;
};

export const Modal: React.FC<Props> = ({ onClose, children }) => {
  useEffect(() => {
    const closeOnEsc = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', closeOnEsc);
    return () => {
      document.removeEventListener('keydown', closeOnEsc);
    };
  }, [onClose]);

  return createPortal(
    <>
      <CoreModal>
        <Close>
          <Icon
            icon={faTimes}
            onClick={onClose}
            size="lg"
            style={{ cursor: 'pointer' }}
          />
        </Close>
        {children}
      </CoreModal>
      <BackDrop />
    </>,
    document.querySelector('#modal-root') as Element,
  );
};

const BackDrop = styled('div', {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  position: 'fixed',
  place: 0,
  zIndex: 490,
});

const CoreModal = styled('div', {
  position: 'fixed',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 500,
  borderRadius: '1.6rem',
  border: '1px solid #afb7ca',
  padding: '1rem',
  backgroundColor: '#ffffff',
  boxShadow:
    '0 0 3px 0 rgba(10, 35, 92, 0.25),0 16px 48px -16px rgba(10, 35, 92, 0.5)',
  '@media (max-width: 960px)': {
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    transform: 'unset',
    borderRadius: 'unset',
  },
});

const Close = styled('div', {
  zIndex: 2,
  position: 'absolute',
  top: '1.5rem',
  right: '1.5rem',
});
